import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { sendPageLoadFocusEvent } from './Events';

const ScrollToTop = ({ history, setShowNavMenu }) => {
    useEffect(() => {
        const unlisten = history.listen(location => {
            setShowNavMenu(false);

            document.body.classList.remove('lock-scroll');

            if (!location.hash) {
                window.scrollTo(0, 0);
                sendPageLoadFocusEvent();
            }
        });
        return () => {
            unlisten();
        };
    }, []);

    return null;
};

export default withRouter(ScrollToTop);
