import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { resources, fallbackLang } from '../../Language';
import PlainTextSection from './PlainTextSection';
import { mediaQueries } from '../../helpers/mediaQueries';
import { pageView } from '../../Tagging';

const Page = styled.main`
    background-color: #F2F2F2;
    padding-top: 30px;
    padding-bottom: 130px;

    ${mediaQueries.greaterThan('lg')`
        padding-top: 60px;
        padding-bottom: 100px;
    `}

    h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;

        ${mediaQueries.greaterThan('lg')`
            font-size: 30px;
            line-height: 36px;
        `}
    }
`

const PlainTextPage = ({ pageKey, pageTag }) => {
    const { t } = useTranslation(pageKey);

    useEffect(() => {
        pageView(pageTag, 'consumer');

        document.addEventListener('skipToMainFocus', () => {
            if (firstFocusableContentRef.current) {
                firstFocusableContentRef.current.focus();
            }
        });
    }, []);

    const sections = resources[fallbackLang][pageKey].sections;

    const firstFocusableContentRef = useRef(null);

    return (
        <Page>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-9">
                        <h1 ref={firstFocusableContentRef} tabIndex="-1" style={{outline: 'none'}}>{t('title')}</h1>
                        {Object.entries(sections).sort((a, b) => a[0].localeCompare(b[0])).map(([key, value]) =>
                            <PlainTextSection key={key} pageKey={pageKey} sectionKey={key} />
                        )}
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default PlainTextPage;

PlainTextPage.propTypes = {
    pageKey: PropTypes.string,
    pageTag: PropTypes.string
};