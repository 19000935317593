import styled from 'styled-components';

import { mediaQueries } from '../../../helpers/mediaQueries';

export const ProtectionPlanSubsection = styled.div`
    margin-top: 25px;
    margin-bottom: 30px;

    ${mediaQueries.greaterThan('md')`
        margin-top: 40px;
        margin-top: 42px;
    `}
`;

export const ProtectionPlanSubsectionHeader = styled.div`
    color: #fff;

    ${mediaQueries.greaterThan('md')`
        text-align: center;
    `}

    h3 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 12px;
        color: inherit;
        font-weight: bold;

        ${mediaQueries.greaterThan('md')`
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 15px;
        `}
    }

    p {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
        color: inherit;

        ${mediaQueries.greaterThan('md')`
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
        `}
    }
`;