import allytm from '@allysf/allytm';

const sectionThreshold = .1;

const pageView = (pageName, customerType) => {
    try {
        const attributes = { customerType };
        window['allytm'].event('pageview', pageName, attributes);
    } catch (error) {
        console.error('TAGGING_SERVICE_NOT_LOADED_PROPERLY');
    }
}

const createSectionObserver = (sectionName, customerType) => {
    return new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio > sectionThreshold) {
            pageView(sectionName, customerType);
        }
    }, { threshold: sectionThreshold });
}

const addAdobeScript = () => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.setAttribute('src', process.env.REACT_APP_TAG_SCRIPT);
    document.head.appendChild(scriptTag);
}

const createDigitalData = () => {
    window['digitalData'] = {
        pageInstanceId: '',
        delayPageLoadEvent: true,
        loadRules: {
          adobeAnalytics: true,
          adobeAudienceManager: true,
          adobeTarget: false,
          celebrus: false,
          clickTale: false,
          googleAnalytics: false,
          livePerson: false,
          confirmit: false,
        },
        page: {
          category: {
            site: 'MotorsProtection',
            subsection: '',
            lineOfBusiness: 'Insurance',
            categoryOfBusiness: 'Consumer'
          },
          attributes: {
            pixelTags: [],
            isSPA: true,
            React: true,
          },
        },
        product: [],
        cart: {},
        transaction: [],
        event: [],
        component: [],
        user: {},
        privacy: {},
        version: 'Ally_1.3'
    };
}

(() => {
    allytm();
    createDigitalData();
    addAdobeScript();
})();

export { pageView, createSectionObserver };