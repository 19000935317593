import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TranslationLink = ({ link, history }) => {
    const getNextRoute = () => {
        const currentRoute = window.location.pathname;
        return currentRoute.match(/^\/fr/) ? currentRoute.replace(/^\/fr/, '') : '/fr' + currentRoute;
    }

    const [currPath, setCurrentPath] = React.useState(getNextRoute());

    useEffect(() => {
        const unlisten = history.listen(() => {
            setCurrentPath(getNextRoute());
        });
        return () => {
            unlisten();
        }
    }, []);

    return (
        <Link allytmln={link.tag} to={currPath}>{link.displayText}</Link>
    );
}

export default withRouter(TranslationLink);

TranslationLink.propTypes = {
    link: PropTypes.object,
    history: PropTypes.any
};