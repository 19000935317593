import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Faqs from './components/Faqs';
import Plans from './components/Plans';
import { pageView } from '../../Tagging';
import GapPlan from './components/GapPlan';
import PageNav from '../../shared/PageNav';
import HeroBanner from './components/HeroBanner';
import ProtectionPlan from './components/ProtectionPlan';
import MaintenancePlan from './components/MaintenancePlan';
import TireProtectionPlan from './components/TireProtectionPlan';
import WhoSection from './components/WhoSection';
import { Helmet } from 'react-helmet';

const Landing = ({ scrolled, setScrolled ,lang}) => {
  useEffect(() => {
    pageView('MotorsProtection:Insurance:Home', 'consumer');
   
  }, []);

  return (
    <main>
      <Helmet  title={lang==='en'?'Motors Protection® | Vehicle Protection Plans & Service Contracts':'Protection Motors®| Plans de protection de véhicule et contrats de service '} meta={[{name:'description',content:lang ==='en'?'Find a vehicle protection product to fit your auto needs. Our products include GAP coverage, tire protection, vehicle maintenance, and vehicle service contracts. ':'Trouvez un produit de protection de véhicule qui répond à vos besoins. Nos produits comprennent la couverture de protection de l’écart, la protection des pneus, l’entretien de véhicule et les contrats de service de véhicule'}]} >
          <link rel="canonical" href={lang ==='en'?'https://www.motorsprotection.ca/':'https://www.motorsprotection.ca/fr/'}></link>
      </Helmet>
      <HeroBanner setScrolled={setScrolled} />
      <PageNav scrolled={scrolled} />
      <Plans scrolled={scrolled} />
      <ProtectionPlan />
      <MaintenancePlan />
      <GapPlan />
      <TireProtectionPlan />
      <WhoSection />
      <Faqs pageKey='landing' />
      
    </main>
  );
};

Landing.propTypes = {
  	setScrolled: PropTypes.func,
    scrolled: PropTypes.bool,
    lang: PropTypes.lang  
};


export default Landing;
