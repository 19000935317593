import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { resources, fallbackLang } from '../../Language';
import { mediaQueries } from '../../helpers/mediaQueries';

const Section = styled.section`
    h2 {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 10px;

        ${mediaQueries.greaterThan('lg')`
            margin-bottom: 15px;
        `}
    }

    p {
        font-size: 14px;
        line-height: 21px;
        margin-top: 10px;

        ${mediaQueries.greaterThan('lg')`
            font-size: 16px;
            line-height: 24px;
        `}
    }
`

const PlainTextSection = ({ pageKey, sectionKey }) => {
    const { t } = useTranslation(pageKey);

    const contents = resources[fallbackLang][pageKey].sections[sectionKey].contents;

    return (
        <Section>
            <h2>{t(`sections.${sectionKey}.title`)}</h2>
            {Object.entries(contents).sort((a, b) => a[0].localeCompare(b[0])).map(([key, value]) =>
                <div key={key} dangerouslySetInnerHTML={{__html: t(`sections.${sectionKey}.contents.${key}`)}} />
            )}
        </Section>
    );
}

export default PlainTextSection;

PlainTextSection.propTypes = {
    pageKey: PropTypes.string,
    sectionKey: PropTypes.string
};