import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import scrollTo from '../helpers/scrollTo';

const StyledNav = styled.nav`
    background-color: #ffffff;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    padding: 18px 0px;

    &.scrolled {
        left: 0;
        top: 0;
        z-index: 100;
        position: sticky;
    }

    ul {
        display: flex;
        list-style: none;
    }

    a.page-links {
        margin-right: 30px;
    }

    span.contact-links {
        display: inline-block;
        margin-left: auto;
    }
`;


const PageNav = ({ scrolled }) => {
	const { t } = useTranslation(['landing','header']);

	let pageLinks = t("pageNav",{ returnObjects: true });

    let pageLinkAttributes = [
        { name: 'Protection Plan', planId: '#protection-plan' },
        { name: 'Maintenance Plan', planId: '#maintenance-plan' },
        { name: 'Guaranteed Auto Protection (GAP)', planId: '#gap-plan' },
        { name: 'Tire Protection Plan', planId: '#tire-protection-plan' },
	];

    return (
        <StyledNav className={`${scrolled ? 'scrolled' : ''} w-100 d-none d-lg-block`}>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <ul>
                            {pageLinks.map((link, idx) => (
                                <li key={link.name}>
                                    <HashLink
										allytmln={`${pageLinkAttributes[idx].name} link (page nav)`}
                                        scroll={(el) => el.scrollIntoView({alignToTop: true}, scrollTo(el))}
                                        to={pageLinkAttributes[idx].planId}
                                        className="page-links">
                                        {link.name}
                                    </HashLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-auto">
                        <span className="contact-links">
                            {t('header:callUs')}{' '}
                            <a allytmln="Phone number link (page nav)" href={`tel:${t('header:phone')}`}>
							{t('header:phone')}
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </StyledNav>
    );
};

PageNav.propTypes = {
	scrolled: PropTypes.bool,
	siteNavScrolled: PropTypes.bool,
	setSiteNavScrolled: PropTypes.func
  };

export default PageNav;
