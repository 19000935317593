import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';
import { resources, fallbackLang } from '../../../../Language';
import './Dejargonator.scss';
import close from '../../../../assets/close.svg';

const SectionTitle = styled.h4`
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    margin: 10px 0;
    outline: none;

    :first-child {
        padding-top: 3px;
        margin-right: 30px;
    }
`;

const SectionDesc = styled.p`
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Button = styled.button`
    border: 0;
    background: none;
    padding: 0;
    position: absolute;
    top: 22px;
    right: 20px;
    font-size: xx-small;

    img {
        height: 16px;
        width: 16px;
    }
`;

const PopoverStyle = styled.div`
    margin: 20px;
`;

const Dejargonator = ({ pageKey, dejargonatorRootKey, firstFocusableContentRef }) => {
    const { t } = useTranslation(pageKey);

    return (
        <Popover>
            <PopoverStyle>
                {Object.entries(_.get(resources[fallbackLang][pageKey], dejargonatorRootKey))
                    .sort((a, b) => a[0].localeCompare(b[0]))
                    .map(([sectionKey, sectionValue], sectionIndex) => (
                        <div key={sectionKey}>
                            <SectionTitle tabIndex="-1" ref={sectionIndex === 0 ? firstFocusableContentRef : null}>
                                {t(`${dejargonatorRootKey}.${sectionKey}.title`)}
                            </SectionTitle>
                            {Object.entries(
                                _.get(
                                    resources[fallbackLang][pageKey],
                                    `${dejargonatorRootKey}.${sectionKey}.paragraphs`
                                )
                            )
                                .sort((a, b) => a[0].localeCompare(b[0]))
                                .map(([paragraphKey, paragraphValue]) => (
                                    <SectionDesc key={paragraphKey}>
                                        {t(`${dejargonatorRootKey}.${sectionKey}.paragraphs.${paragraphKey}`)}
                                    </SectionDesc>
                                ))}
                        </div>
                    ))}
                <Button type="button" aria-label={t(`generalNav.closeTooltip`)} onClick={() => document.body.click()}>
                    <img src={close} alt="" />
                </Button>
            </PopoverStyle>
        </Popover>
    );
};

Dejargonator.propTypes = {
    pageKey: PropTypes.string,
    dejargonatorRootKey: PropTypes.string,
    firstFocusableContentRef: PropTypes.ref
};

export default Dejargonator;
