import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mediaQueries } from '../../../../helpers/mediaQueries';
import { ProtectionPlanSubsection, ProtectionPlanSubsectionHeader } from '../ProtectionPlanStyles';
import { resources, fallbackLang } from '../../../../Language';
import checkmark from '../../../../assets/mpp-checkmark.png';
import DejargonatorTrigger from './DejargonatorTrigger';
import leaf from '../../../../assets/mpp-leaf.png';

const coveredPartsMapping = {
    '01': [true, true, true, false],
    '02': [true, true, true, false],
    '03': [true, true, true, false],
    '04': [true, false, false, true],
    '05': [true, false, false, false],
    '06': [true, true, false, false],
    '07': [true, true, false, false],
    '08': [true, true, false, false],
    '09': [true, true, false, false],
    10: [true, true, false, false],
    11: [true, false, false, false],
    12: [true, true, false, false],
    13: [true, false, false, false],
    14: [true, false, false, false],
    15: [true, false, false, false],
    16: [true, false, false, false],
};

const Table = styled.table`
    width: 100%;
    margin: auto;
    margin-top: 20px;

    ${mediaQueries.greaterThan('md')`
        width: 620px;
    `}

    ${mediaQueries.greaterThan('lg')`
        width: 800px;
    `}

    ${mediaQueries.greaterThan('xl')`
        width: 922px;
    `}

    th {
        text-transform: uppercase;
        background-color: #e3ecf1;
        font-size: 11px;
        line-height: 13px;
        padding-top: 10px;
        padding-bottom: 10px;

        ${mediaQueries.greaterThan('lg')`
            padding-top: 12px;
            padding-bottom: 12px;
        `}
    }

    td {
        font-size: 14px;
        line-height: 21px;
        padding-top: 6px;
        padding-bottom: 6px;

        ${mediaQueries.greaterThan('lg')`
            line-height: 45px;
            padding-top: 0;
            padding-bottom: 0;
        `}
    }

    tbody tr {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    tr.even {
        background-color: #fff;
    }

    tr.odd {
        background-color: #f8f8f8;
    }

    td,
    th {
        padding-left: 4px;
        padding-right: 4px;
    }

    td:first-child,
    th:first-child {
        padding-left: 9px;

        ${mediaQueries.greaterThan('lg')`
            padding-left: 20px;
        `}
    }

    td:last-child,
    th:last-child {
        padding-right: 9px;

        ${mediaQueries.greaterThan('lg')`
            padding-right: 20px;
        `}
    }

    .checkmark {
        height: 15px;
        width: 18px;
        background-image: url('');
        background-position: center;
        background-size: cover;
        margin: auto;
        display: list-item;

        &.checked {
            background-image: url(${checkmark});
        }
    }
`;

const PlanOptions = () => {
    const { t } = useTranslation('landing');
    const headings = resources[fallbackLang].landing.protectionPlan.planOptions.headings;
    const firstColumnValues = resources[fallbackLang].landing.protectionPlan.planOptions.coveredParts;
    const planOptionsKey = 'protectionPlan.planOptions';
    const [openPopover, setOpenPopover] = useState(null);

    return (
        <ProtectionPlanSubsection>
            <ProtectionPlanSubsectionHeader>
                <h3>{t(`${planOptionsKey}.title`)}</h3>
            </ProtectionPlanSubsectionHeader>

            <Table>
                <caption className="visually-hidden-focusable">{t(`${planOptionsKey}.caption`)}</caption>

                <thead>
                    <tr>
                        {Object.entries(headings)
                            .sort((a, b) => a[0].localeCompare(b[0]))
                            .map(([key, value], index) => (
                                <th
                                    key={index}
                                    aria-label={t(`${planOptionsKey}.headings.${key}.accessibleText`)}
                                    className={index !== 0 ? 'text-center' : ''}
                                    dangerouslySetInnerHTML={{
                                        __html: t(`${planOptionsKey}.headings.${key}.displayText`),
                                    }}
                                />
                            ))}
                    </tr>
                </thead>

                <tbody>
                    {Object.entries(firstColumnValues)
                        .sort((a, b) => a[0].localeCompare(b[0]))
                        .map(([key, value], rowIndex) => {
                            return (
                                <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'even' : 'odd'}>
                                    <td className="text-left">
                                        <DejargonatorTrigger
                                            pageKey={'landing'}
                                            dejargonatorsRootKey={`${planOptionsKey}.coveredParts`}
                                            dejargonatorKey={key}
                                            openPopover={openPopover}
                                            setOpenPopover={setOpenPopover}
                                            icon={key === '04' || key === '05' ? leaf : null}
                                        />
                                    </td>
                                    {coveredPartsMapping[key].map((columnValue, columnIndex) => {
                                        const ariaLabel =
                                            t(`${planOptionsKey}.coveredParts.${key}.accessibleText`) +
                                            ' ' +
                                            (columnValue
                                                ? t(`${planOptionsKey}.included`)
                                                : t(`${planOptionsKey}.excluded`));

                                        return (
                                            <td key={columnIndex}>
                                                <img
                                                    className={'checkmark ' + (columnValue ? 'checked' : '')}
                                                    aria-label={ariaLabel}
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </ProtectionPlanSubsection>
    );
};

export default PlanOptions;
