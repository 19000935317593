import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { createSectionObserver } from '../../../Tagging';
import { mediaQueries } from '../../../helpers/mediaQueries';

const StyledWrapper = styled.section`
	background-color: #5e295f;

	padding: 40px 0px;
    ${mediaQueries.greaterThan('md')`
        padding: 65px 0px 40px 0px;
    `}

    .gap__title {

		h2 {
			outline: none;

			font-size: 24px;
			line-height: 36px;
			${mediaQueries.greaterThan('md')`
				font-size: 30px;
				line-height: 45px;
			`}
		}

        ${mediaQueries.greaterThan('lg')`
            padding-bottom 60px;
        `}

		span {

			${mediaQueries.greaterThan('lg')`
					display: block;
			`}
		}

		span,
        b {
            color: #fff;
            font-size: 24px;
            line-height: 36px;

            ${mediaQueries.greaterThan('lg')`
                font-size: 30px;
                line-height: 34px;
            `}
        }
    }

    .gap-description__inner {
        padding: 1.25rem;
        font-size: 14px;
        line-height: 21px;
        background-color: #fff;
        border: 1px solid #979797;

        ${mediaQueries.greaterThan('lg')`
            font-size: 1rem;
            padding: 2.1rem;
        `}

        p {
            color: #2a2a2a;
        }

        ul {
            padding-left: 0;
            li {
                list-style-position: outside;
                line-height: 21px;
            }
                li::marker {
                padding-right: 10px;
            }
        }

        .gap__what {
            font-size: 20px;
            line-height: 30px;
            font-weight: bold;

            ${mediaQueries.greaterThan('lg')`
                font-size: 24px;
                line-height: 28px;
            `}
        }
    }

    .carrier-info {
        font-size: 14px;
        line-height: 16px;

        ${mediaQueries.greaterThan('lg')`
            font-size: 16px;
            line-height: 18px;
            max-width: 430px;
        `}

        p {
            margin-bottom: 20px;
        }

        img {
            width: 100%;
        }
    }

    p {
    color: #fff;
    }
`;

const GapPlan = () => {
    const { t } = useTranslation('landing');
    let parts = t(`gapPlan.what.parts`, { returnObjects: true });

    const observer = createSectionObserver('MotorsProtection:Insurance:Guaranteed Auto Protection', 'consumer');

    useEffect(() => {
        observer.observe(document.querySelector('#gap-plan'));
    }, []);
    
    const imageUrl = () => {
        const translated = t('gapPlan.coverageDetails.image');
        return require(`../../../assets/${translated}`);
    };

    return (
        <StyledWrapper id="gap-plan">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between">
                    <div className="gap__title col-12 text-left text-md-center">
                        <h2 tabIndex="-1" dangerouslySetInnerHTML={{ __html: t('gapPlan.title') }}></h2>
                    </div>
                    <div className="col-12 col-lg-6 my-3 pb-3 ">
                        <div className="gap-description__inner">
                            <p className="mb-2">{t('gapPlan.description1')}</p>
                            <p dangerouslySetInnerHTML={{ __html: t('gapPlan.description2') }}></p>
                            <h3 className="mb-3 mt-4 ">{t('gapPlan.what.title')}</h3>
                            <ul className="mb-3 pl-0 pl-3">
                                {Object.entries(parts)
                                    .sort((a, b) => a[0].localeCompare(b[0]))
                                    .map(([key, value]) => (
                                        <li className="pl-3 mb-3" key={`part-${key}`}>
                                            <span>{value}</span>
                                        </li>
                                    ))}
                            </ul>
                            <p>{t('gapPlan.what.authorizedDealer')}</p>
                        </div>
                    </div>

                    <div className="carrier-info col-12 col-lg-6 mb-3">
                        <img
                            className="text-right pb-4"
                            src={imageUrl()}
                            alt={`${t('gapPlan.coverageDetails.imageAltText')}`}
                        />
                        <CoverageDetails t={t} />
                        <p>{t('gapPlan.coverageDetails.coverageDescription')}</p>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    );
};

const CoverageWrapper = styled.div`
    hr {
        width: 100%;
        margin-top: -5px;
        border-top: 1px solid #fff;
    }

    p {
        flex: 2;
        color: #fff;
        font-size: 14px;
    }

    div: {
        display: flex;
        p:first-child {
            flex: 2;
        }
        p:last-child {
            flex: 1;
        }
    }
`;

const CoverageDetails = ({ t }) => {
    return (
        <CoverageWrapper>
            <div className="carrier-info w-100">
                <div className="d-flex pb-1">
                    <p className="flex-grow-4">{t('gapPlan.coverageDetails.balanceContract')}</p>
                    <p className="text-right flex-grow-1">{t('gapPlan.coverageDetails.balanceContractValue')}</p>
                </div>

                <div className="d-flex pb-1">
                    <p className="flex-grow-4">{t('gapPlan.coverageDetails.actualCash')}</p>
                    <p className="text-right flex-grow-1">{t('gapPlan.coverageDetails.actualCashValue')}</p>
                </div>

                <div className="d-flex pb-1">
                    <p className="flex-grow-4">{t('gapPlan.coverageDetails.lessDeductible')}</p>
                    <p className="text-right flex-grow-1">{t('gapPlan.coverageDetails.lessDeductibleValue')}</p>
                </div>

                <div className="d-flex">
                    <p className="flex-grow-4">{t('gapPlan.coverageDetails.carriersPayment')}</p>
                    <p className="text-right flex-grow-1">{t('gapPlan.coverageDetails.carriersPaymentValue')}</p>
                </div>

                <div>
                    <hr />
                </div>

                <div className="d-flex pb-2">
                    <p className="flex-grow-4">{t('gapPlan.coverageDetails.amountCovered')}</p>
                    <p className="text-right flex-grow-1">{t('gapPlan.coverageDetails.amountCoveredValue')}</p>
                </div>
            </div>
        </CoverageWrapper>
    );
};

CoverageDetails.propTypes = {
    t: PropTypes.func,
};

export default GapPlan;
