import useMedia from './useMedia';

/**
 *
 * @param {*} el
 * @param {*} scrolled
 * @param {*} isMobile
 */
export const scrollTo = (el, scrolled, isMobile) => {

	let yOffset = (scrolled || isMobile) ? 0 : -64;

	const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
	const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;

	if (isIE11) {
		// scrollTo without options causes scroll jump issue on firefox.
		// otherwise it could be used for both IE11 and other evergreen browsers
        setTimeout(() => {
            window.scrollTo(0, yCoordinate + yOffset);
        }, 1);
	} else {
		window.scrollTo({ top: yCoordinate + yOffset });
	}

	setTimeout(() => {
		el.querySelector('h2').focus();
	}, 1);

};

export default scrollTo;
