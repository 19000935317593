import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TranslationLink from './TranslationLink';

const Wrapper = styled.div`
    left: 0;
    top: 64px;
    width: 100vw;
    z-index: 9999;
    height: 100vh;
    position: fixed;
    background-color: #5e295f;

    .nav-menu__links {
        div {
            position: relative;

            a {
                color: #fff;
                display: block;
                font-size: 14px;
                line-height: 17px;
                padding: 1.5em 0;
                text-transform: uppercase;
            }
            hr {
                left: -20%;
                width: 200%;
                position: absolute;
                margin: 0;
                border-bottom: 1px solid #fff;
            }
        }
    }
`;

const NavMenu = () => {
    const { t } = useTranslation('header');
    const handleLanguageToggle = () => console.log('toggle language');

    return (
        <Wrapper>
            <nav className="nav-menu__links">
                <div className="container">
                    {false && (
                        <div>
                            <Link allytmln="Consumer link" to={t('homeRoute')}>
                                {t('consumerPage')}
                            </Link>
                            <hr />
                        </div>
                    )}
                    {false && (
                        <div>
                            <Link allytmln="Home link" to={t('homeRoute')}>
                                {t('dealerPage')}
                            </Link>
                            <hr />
                        </div>
                    )}

                    <div>
                        <TranslationLink link={{ tag: `Change language to ${t('languageLink')} link`, displayText: t('languageLink') }} />
                        <hr />
                    </div>
                </div>
            </nav>
        </Wrapper>
    );
};

export default NavMenu;
