import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import scrollTo from '../../../helpers/scrollTo';
import { resources } from '../../../Language';
import { mediaQueries } from '../../../helpers/mediaQueries';
import { HashLink } from 'react-router-hash-link';

const StyledWrapper = styled.div`
    font-size: 0.875rem;
    padding: 1.875rem 0;
    background-color: #f2f2f2;

    a {
        text-align: start;
        margin-bottom: 5px;

        ${mediaQueries.greaterThan('lg')`
            text-align: center;
        `}
    }

    ${mediaQueries.greaterThan('lg')`
        font-size: 1rem;
    `}

    .plans__content {
        padding: 0;
        margin-bottom: 1.8rem;

        ${mediaQueries.greaterThan('lg')`
        padding: 0 .8rem;
        `}

        .plans__title {
            font-size: 1.5em;
            line-height: 24px;
            font-weight: bold;

            ${mediaQueries.greaterThan('md')`
            font-size: 1.8em;
            line-height: 36px;
        `}
        }
    }
    a {
        color: #0071c4;
        font-weight: bold;
    }

    img {
        width: 73px;
        height: 73px;
    }
`;

const Plans = ({ scrolled }) => {
    const pageKey = 'landing';
	const { t } = useTranslation(pageKey);

    return (
        <StyledWrapper className="plans">
            <div className="container">
                <div className="row justify-content-start justify-content-md-center">
                    <div className="col-12 col-lg-9">
                        <div className="plans__content text-left text-md-center">
                            <h2 className="plans__title">{t('plans.title')}</h2>
                            <p dangerouslySetInnerHTML={{ __html: t('plans.content') }}></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="row">
                            <Plan pageKey={pageKey} sectionKey={'1'} scrolled={scrolled} />
                            <Plan pageKey={pageKey} sectionKey={'2'} scrolled={scrolled} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="row">
                            <Plan pageKey={pageKey} sectionKey={'3'} scrolled={scrolled} />
                            <Plan pageKey={pageKey} sectionKey={'4'} scrolled={scrolled} />
                        </div>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    );
};

Plans.propTypes = {
	pageKey: PropTypes.string,
	scrolled: PropTypes.bool
};

const StyledPlan = styled.div`
    ${mediaQueries.greaterThan('lg')`
        margin-bottom: 0;
        align-items: center;
    `}

    .plan__links {
        color: #0071c4;
        text-align: left;
        font-weight: bold;

        ${mediaQueries.greaterThan('lg')`
			margin-bottom: 5px;
			text-align: center;
    	`}

        &:hover {
            text-decoration: underline;
        }
    }
`;

const Plan = ({ pageKey, sectionKey }) => {
	const { t } = useTranslation(pageKey);

    const imageUrl = sectionKey => {
        const translated = t(`plans.sections.${sectionKey}.img`);
        return require(`../../../assets/${translated}`);
    };

    const linkTo = t(`plans.sections.${sectionKey}.linkTo`);
	const title = t(`plans.sections.${sectionKey}.title`);
	const titleForTagging = `${resources['en'].landing.plans.sections[sectionKey].title}`;

    return (
        <StyledPlan className={`col-12 col-md-6 text-md-center mb-2 mb-lg-0`}>
            <div className="row justify-content-start justify-content-md-center">
                <div className="col-3 col-md-12 text-left text-md-center mb-3">
                    <img alt="" src={imageUrl(sectionKey)} />
                </div>
                <div className="col-9 col-md-12">
                    <HashLink
						smooth to={linkTo}
                        scroll={(el) => el.scrollIntoView({alignToTop: true}, scrollTo(el))}
                        className="plan__links"
                        allytmln={`${titleForTagging} link (plans section)`}>
                        {`${title}`}
                    </HashLink>
                    <p>{t(`plans.sections.${sectionKey}.content`)}</p>
                </div>
            </div>
        </StyledPlan>
    );
};

Plan.propTypes = {
	t: PropTypes.func,
	scrolled: PropTypes.bool,
    pageKey: PropTypes.string,
    sectionKey: PropTypes.string,
};

export default Plans;
