import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { createSectionObserver } from '../../../Tagging';
import { mediaQueries } from '../../../helpers/mediaQueries';
import { resources, fallbackLang } from '../../../Language';

const StyledSection = styled.section`
    background-color: #f2f2f2;
    padding: 40px 0px;

    ${mediaQueries.greaterThan('md')`
        padding: 65px 0px 40px 0px;
    `}

    h2 {
        outline: none;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 20px;

        ${mediaQueries.greaterThan('md')`
            font-size: 30px;
            line-height: 45px;
            margin-bottom: 15px;
        `}
    }

    h3 {
        font-size: 20px;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 12px;

        ${mediaQueries.greaterThan('md')`
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 15px;
        `}
    }

    p {
        font-size: 14px;
        line-height: 21px;
        margin-top: 10px;
        margin-bottom: 10px;

        ${mediaQueries.greaterThan('md')`
            font-size: 16px;
            line-height: 24px;
        `}
    }

    ul {
        margin-left: 2em;

        li {
            font-size: 14px;
            line-height: 21px;

            ${mediaQueries.greaterThan('md')`
                font-size: 16px;
                line-height: 24px;
            `}
        }
    }

    .administered {
        margin-top: 30px;
    }
`;

const TireProtectionPlan = () => {
    const { t } = useTranslation('landing');
    const benefits = resources[fallbackLang].landing.tireProtectionPlan.benefits.items;

    const observer = createSectionObserver('MotorsProtection:Insurance:Tire Protection Plan', 'consumer');

    useEffect(() => {
        observer.observe(document.querySelector('#tire-protection-plan'));
    }, []);

    return (
        <StyledSection id="tire-protection-plan">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h2 tabIndex="-1">
                            <b>{t('tireProtectionPlan.title')}</b> {t('tireProtectionPlan.subtitle')}
                        </h2>
                        <p>{t('tireProtectionPlan.paragraphs.1')}</p>
                        <p>{t('tireProtectionPlan.paragraphs.2')}</p>
                        <p>{t('tireProtectionPlan.paragraphs.3')}</p>
                    </div>
                    <div className="col-lg-6 mt-3 mt-lg-0">
                        <h3>{t('tireProtectionPlan.benefits.title')}</h3>
                        <ul>
                            {Object.entries(benefits)
                                .sort((a, b) => a[0].localeCompare(b[0]))
                                .map(([key, value]) => (
                                    <li key={key}>{t(`tireProtectionPlan.benefits.items.${key}`)}</li>
                                ))}
                        </ul>
                    </div>
                </div>

                <h3 className="mt-3">{t('tireProtectionPlan.planOptions.title')}</h3>
                <div className="row no-gutters">
                    <div className="col-lg-6 mb-2 pr-lg-2">
                        <PlanOption planOptionKey="1" t={t} />
                    </div>
                    <div className="col-lg-6 mb-2 mt-2 mt-lg-0 pl-lg-2">
                        <PlanOption planOptionKey="2" t={t} />
                    </div>
                    <div className="col-lg-6 mb-2 mb-lg-0 mt-2 pr-lg-2">
                        <PlanOption planOptionKey="3" t={t} />
                    </div>
                    <div className="col-lg-6 mt-2 pl-lg-2">
                        <PlanOption planOptionKey="4" t={t} />
                    </div>
                </div>

                <p className="administered">{t('tireProtectionPlan.administered')}</p>
            </div>
        </StyledSection>
    );
};

const StyledPlanOption = styled.div`
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #979797;
    min-height: 100%;

    ${mediaQueries.greaterThan('md')`
        padding: 20px 30px;
    `}

    h4 {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 5px;

        ${mediaQueries.greaterThan('md')`
            margin-bottom: 8px;
        `}
    }

    p {
        font-size: 14px;
        line-height: 21px;
        margin-top: 0;
        margin-bottom: 5px;

        ${mediaQueries.greaterThan('md')`
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
        `}
    }
`;

const PlanOption = ({ planOptionKey, t }) => {
    const baseKey = `tireProtectionPlan.planOptions.sections.${planOptionKey}`;
    const items = resources[fallbackLang].landing.tireProtectionPlan.planOptions.sections[planOptionKey].items;

    return (
        <StyledPlanOption>
            <h4>{t(`${baseKey}.title`)}</h4>
            <p>{t(`${baseKey}.description`)}</p>
            <ul>
                {Object.entries(items)
                    .sort((a, b) => a[0].localeCompare(b[0]))
                    .map(([key, value]) => (
                        <li key={key}>{t(`${baseKey}.items.${key}`)}</li>
                    ))}
            </ul>
        </StyledPlanOption>
    );
};

PlanOption.propTypes = {
    planOptionKey: PropTypes.string,
    t: PropTypes.func,
};

export default TireProtectionPlan;
