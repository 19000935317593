import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { resources, fallbackLang } from '../../../Language';
import { ProtectionPlanSubsection, ProtectionPlanSubsectionHeader } from './ProtectionPlanStyles';
import { mediaQueries } from '../../../helpers/mediaQueries';
import questionCirleIcon from '../../../assets/question-circle.svg';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import close from '../../../assets/close.svg';
import './AdditionalBenefits.scss';
import questionMark from '../../../assets/dejarg-question-mark.cur';
import _ from 'lodash';

const additionalBenefitsKey = 'protectionPlan.additionalBenefits';

const SubsectionFooter = styled.div`
    color: #fff;
    margin-top: 20px;

    a {
        color: inherit;
        text-decoration: underline;
    }

    p {
        font-size: 14px;
        line-height: 21px;
        margin-top: 10px;

        ${mediaQueries.greaterThan('md')`
            font-size: 16px;
            line-height: 24px;
        `}
    }

    p,
    div {
        color: inherit;
    }
`;

const AdditionalBenefits = () => {
    const { t } = useTranslation('landing');

    return (
        <ProtectionPlanSubsection>
            <ProtectionPlanSubsectionHeader>
                <h3>{t(`${additionalBenefitsKey}.title`)}</h3>
                <p>{t(`${additionalBenefitsKey}.description`)}</p>
            </ProtectionPlanSubsectionHeader>

            <div className="row no-gutters">
                {/* Benefit2 is used for screen reader purposes */}
                <div className="col-lg-6 mb-2 pr-lg-2">
                    <Benefit2 sectionSubkey="01" imageUrl={require('../../../assets/mpp-rental-car.png')} />
                </div>
                <div className="col-lg-6 mb-2 mt-2 mt-lg-0 pl-lg-2">
                    <Benefit sectionSubkey="02" imageUrl={require('../../../assets/mpp-24hour-roadside.png')} />
                </div>
                <div className="col-lg-4 mb-2 mb-lg-0 mt-2 pr-lg-2">
                    <Benefit sectionSubkey="03" />
                </div>
                <div className="col-lg-4 mb-2 mb-lg-0 mt-2 px-lg-2">
                    <Benefit sectionSubkey="04" />
                </div>
                <div className="col-lg-4 mt-2 pl-lg-2">
                    <Benefit sectionSubkey="05" />
                </div>
            </div>

            <SubsectionFooter>
                <div className="row">
                    <div className="col-lg-8">
                        <p dangerouslySetInnerHTML={{ __html: t(`${additionalBenefitsKey}.limitations`) }} />
                        <p>{t(`${additionalBenefitsKey}.administered`)}</p>
                    </div>
                </div>
            </SubsectionFooter>
        </ProtectionPlanSubsection>
    );
};

const StyledBenefit = styled.div`
    background: #fff;
    min-height: 100%;
    padding: 20px;

    img {
        padding-right: 25px;
        height: 60px;
    }

    h4 {
        font-size: 20px;
        line-height: 23px;
        font-weight: bold;
        display: contents;
    }

    p.subtitle {
        font-size: 18px;
        line-height: 21px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    p.desc {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 0;

        ${mediaQueries.greaterThan('md')`
            font-size: 16px;
            line-height: 24px;
        `}
    }
`;

const TooltipStyle = styled.button`
    border: 0;
    background: none;
    width: 20px;
    height: 17px;
    position: absolute;
    background-size: 100% 100%;
    margin-top: 3px;
    display: inline-block;
    background-image: url(${questionCirleIcon});
    :hover,
    :focus {
        cursor: url(${questionMark}), help;
    }
`;

const SectionTitle = styled.h5`
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    margin: 10px 0;
    outline: none;
    :focus {
        outline: 1px black;
    }
    :first-child {
        padding-top: 3px;
        margin-right: 30px;
    }
`;

const SectionDesc = styled.p`
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Button = styled.button`
    border: 0;
    background: none;
    padding: 0;
    position: absolute;
    top: 22px;
    right: 20px;
    font-size: xx-small;
    float: right;
    div {
        background-image: url(${close});
        height: 16px;
        width: 16px;
    }
`;

const HiddenElement = styled.span`
    padding: 0;
    margin: 0;
    height: 0;
    width: 0;
`;

const PopoverStyle = styled.div`
    margin: 20px;
`;

const Benefit = ({ sectionSubkey, imageUrl }) => {
    const { t } = useTranslation('landing');
    const sectionKey = `${additionalBenefitsKey}.sections.${sectionSubkey}`;
    const [openPopover, setOpenPopover] = useState(null);

    return (
        <StyledBenefit className="d-flex align-items-center">
            {imageUrl && <img className="d-none d-lg-block" src={imageUrl} alt="" />}
            <div className="align-self-start">
                <h4>{t(`${sectionKey}.title`)}</h4>
                {_.get(resources[fallbackLang]['landing'], sectionKey + '.popover') && (
                    <TriggerTooltip
                        page={'landing'}
                        sectionRootkey={`${additionalBenefitsKey}.sections`}
                        sectionSubKey={sectionSubkey}
                        openPopover={openPopover}
                        setOpenPopover={setOpenPopover}></TriggerTooltip>
                )}
                <p className="subtitle">{t(`${sectionKey}.subtitle`)}</p>
                <p className="desc">{t(`${sectionKey}.description`)}</p>
            </div>
        </StyledBenefit>
    );
};

// needed for accessibility purposes
const Benefit2 = ({ sectionSubkey, imageUrl }) => {
    const { t } = useTranslation('landing');
    const sectionKey = `${additionalBenefitsKey}.sections.${sectionSubkey}`;
    const [openPopover, setOpenPopover] = useState(null);

    return (
        <StyledBenefit className="d-flex align-items-center">
            {imageUrl && <img className="d-none d-lg-block" src={imageUrl} alt="" />}
            <div className="align-self-start">
                <h4 aria-Label={t(`${sectionKey}.titleAndSub`)}>{t(`${sectionKey}.title`)}</h4>
                {_.get(resources[fallbackLang]['landing'], sectionKey + '.popover') && (
                    <TriggerTooltip
                        page={'landing'}
                        sectionRootkey={`${additionalBenefitsKey}.sections`}
                        sectionSubKey={sectionSubkey}
                        openPopover={openPopover}
                        setOpenPopover={setOpenPopover}></TriggerTooltip>
                )}
                <p aria-hidden="true" className="subtitle">
                    {t(`${sectionKey}.subtitle`)}
                </p>
                <p className="desc">{t(`${sectionKey}.description`)}</p>
            </div>
        </StyledBenefit>
    );
};

// needed so that it doesn't compile with errors
Benefit.propTypes = {
    sectionSubkey: PropTypes.string,
    imageUrl: PropTypes.string
};

// needed so that compile warnings do not arise
Benefit2.propTypes = {
    sectionSubkey: PropTypes.string,
    imageUrl: PropTypes.string
};

const TriggerTooltip = ({ page, sectionRootkey, sectionSubKey, openPopover, setOpenPopover }) => {
    const { t } = useTranslation(page);
    const isPopoverShowing = () => openPopover === sectionSubKey;
    const triggerRef = React.useRef(null);
    const firstFocusableContentRef = React.useRef(null);
    const parentRef = React.useRef(null);
    const triggerRootKey = sectionRootkey + '.' + sectionSubKey;

    sectionRootkey += '.' + sectionSubKey + '.popover.sections';

    const showPopover = (show) => {
        if (show) {
            setOpenPopover(sectionSubKey);
        } else {
            if (openPopover) {
                setOpenPopover(null);
                setTimeout(() => triggerRef.current.focus(), 300);
            }
        }
    };

    const hiddenElement = (
        <HiddenElement aria-label="" onFocus={() => showPopover(false)} tabIndex={isPopoverShowing() ? '0' : '-1'} />
    );

    return (
        <>
            <span ref={parentRef}>
                <TooltipStyle
                    ref={triggerRef}
                    onClick={() => showPopover(!isPopoverShowing())}
                    onFocus={() => showPopover(false)}
                    onMouseEnter={() => showPopover(true)}
                    onMouseLeave={() => showPopover(false)}
                    type="button"
                    aria-label={`${t(`generalNav.selecttoLearnMoreAbout`)} ${t(
                        `${triggerRootKey}.accessibleText`
                    )}`}></TooltipStyle>
                <Overlay
                    show={isPopoverShowing()}
                    target={triggerRef.current}
                    placement="bottom"
                    container={parentRef}
                    rootClose={true}
                    onHide={() => showPopover(false)}
                    onEntered={() => firstFocusableContentRef.current.focus()}
                    allytmln={`${_.get(resources['en'][page], `${triggerRootKey}.accessibleText`)} tooltip`}>
                    <Popover>
                        <PopoverStyle>
                            {Object.entries(_.get(resources[fallbackLang][page], sectionRootkey))
                                .sort((a, b) => a[0].localeCompare(b[0]))
                                .map(([sectionKey, sectionValue], sectionIndex) => (
                                    <div key={sectionKey}>
                                        <SectionTitle
                                            tabIndex="-1"
                                            ref={sectionIndex === 0 ? firstFocusableContentRef : null}>
                                            {t(`${sectionRootkey}.${sectionKey}.title`)}
                                        </SectionTitle>
                                        {Object.entries(
                                            _.get(
                                                resources[fallbackLang][page],
                                                `${sectionRootkey}.${sectionKey}.paragraphs`
                                            )
                                        )
                                            .sort((a, b) => a[0].localeCompare(b[0]))
                                            .map(([paragraphKey, paragraphValue]) => (
                                                <SectionDesc key={paragraphKey}>
                                                    {t(`${sectionRootkey}.${sectionKey}.paragraphs.${paragraphKey}`)}
                                                </SectionDesc>
                                            ))}
                                    </div>
                                ))}
                            <Button
                                type="button"
                                aria-label={t(`generalNav.closeTooltip`)}
                                onClick={() => document.body.click()}>
                                <div alt="" />
                            </Button>
                        </PopoverStyle>
                    </Popover>
                </Overlay>
                {isPopoverShowing() && hiddenElement}
            </span>
        </>
    );
};

TriggerTooltip.propTypes = {
    page: PropTypes.string,
    sectionRootkey: PropTypes.string,
    sectionSubKey: PropTypes.string,
    openPopover: PropTypes.string,
    setOpenPopover: PropTypes.func,
};

export default AdditionalBenefits;
