import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { mediaQueries } from '../../../helpers/mediaQueries';
import heroScribble from '../../../assets/mpp-hero.png';

const StyledHeroBanner = styled.div`
  margin-top: 50px;
  margin-bottom: 55px;

  ${mediaQueries.greaterThan("lg")`
    margin-top: 70px;
    margin-bottom: 60px;
  `}

  img {
    width: 445px;
    display: none;

    ${mediaQueries.greaterThan("lg")`
      display: inline;
    `}
  }

  h1 {
    font-family: Century Gothic Regular, sans-serif;
    font-size: 20px;
    line-height: 30px;
    outline: none;

    ${mediaQueries.greaterThan("lg")`
        font-size: 24px;
        line-height: 36px;
    `}
}

  p.hero-banner__title {
    font-size: 24px;
    color: #5E295F;
    font-family: century-gothic-bold, sans-serif;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;

    ${mediaQueries.greaterThan("lg")`
        font-size: 40px;
        line-height: 43px;
    `}
  }

  p.description {
    font-size: 16px;
    line-height: 24px;
    padding-right: 2rem;
    padding-left: 2rem;

    ${mediaQueries.greaterThan("lg")`
        font-size: 20px;
        line-height: 30px;
        padding-right: 3.5rem;
        padding-left: 3.5rem;
    `}
  }
`;

const HeroBanner = ({ setScrolled }) => {
  const { t } = useTranslation('landing');

  const observer = new IntersectionObserver((entries) => {
    setScrolled(!entries[0].isIntersecting);
  }, { });

  useEffect(() => {
    observer.observe(document.querySelector("#hero-banner"));

    document.addEventListener('skipToMainFocus', () => {
      if (firstFocusableContentRef.current) {
        firstFocusableContentRef.current.focus();
      }
    });
  }, []);

  const firstFocusableContentRef = useRef(null);

  return (
    <section id="hero-banner">
      <StyledHeroBanner className="container">
        <div className="row justify-content-between">
          <div className="col">
            <img src={heroScribble} alt="" />
          </div>
          <div className="col-lg-6 col-xl-7 mt-0 mt-lg-4">
          <h1 ref={firstFocusableContentRef} tabIndex="-1" className="sr-only-focusable text-center">
              {t('hero.pageTitle')}
            </h1>
            <p className="mb-4 text-center hero-banner__title">{t('hero.sectionTitle')}</p>
            <p className="description text-center" dangerouslySetInnerHTML={{ __html: t('hero.contact') }} />
          </div>
        </div>
      </StyledHeroBanner>
    </section>
  );
};

HeroBanner.propTypes = {
  setScrolled: PropTypes.func
};

export default HeroBanner;
