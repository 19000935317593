import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import scrollTo from '../../../helpers/scrollTo';
import { createSectionObserver } from '../../../Tagging';
import { mediaQueries } from '../../../helpers/mediaQueries';

import oil from '../../../assets/maintenance-oil.png';
import checkMark from '../../../assets/mpp-checkmark.png';

const StyledWrapper = styled.section`
  background-color: #f2f2f2;

  padding: 40px 0px;
    ${mediaQueries.greaterThan('md')`
        padding: 65px 0px 40px 0px;
    `}

  }

  .title-section {
    h2 {
        outline: none;
        word-spacing: 0.15em;
        font-size: 24px;
        line-height: 36px;
        ${mediaQueries.greaterThan('md')`
            font-size: 30px;
            line-height: 45px;
        `}
    }

  }

  .administered-by {
    img {
        height: 25px;
        max-width: 100%;

        ${mediaQueries.greaterThan('lg')`
            height: 41px;
        `}
      }
      p {
            font-size: 14px;
            line-height: 21px;

        ${mediaQueries.greaterThan('lg')`
            font-size: 1rem;
            line-height: 24px;
        `}
      }
  }

  .whats-covered {
    h3 {
      font-size: 20px;
    }

    ${mediaQueries.greaterThan('lg')`
        font-size: 24px;
    `}
  }

  ul {
    font-size: 14px;
    lin-height: 21px;

    li {
      list-style: none;

      img {
          width: 18px;
          height: 15px;
          margin-right: 15px;
      }
    }
  }
`;

const MaintenancePlan = () => {
    const { t } = useTranslation('landing');

    let parts = t(`maintenance.whatsCovered.sections`, { returnObjects: true });

    const observer = createSectionObserver('MotorsProtection:Insurance:Maintenance Plan', 'consumer');

    useEffect(() => {
        observer.observe(document.querySelector('#maintenance-plan'));
    }, []);
    return (
        <StyledWrapper id="maintenance-plan">
            <div className="container">
                <div className="row w-100">
                    <div className="title-section col-auto col-lg-6 pb-2 order-1">
                        <div className="mb-2 mb-lg-4">
                            <h2
                                tabIndex="-1"
                                className="pb-1"
                                dangerouslySetInnerHTML={{ __html: t('maintenance.title') }}></h2>
                            <p>{t('maintenance.description')}</p>
                        </div>
                        <div className="administered-by d-none d-lg-flex align-items-center text-left mb-2">
                            <img className="mr-2" src={oil} alt="" />
                            <p>{t('maintenance.administeredBy')}</p>
                        </div>
                    </div>
                    <div className="col-auto col-lg-6 administered-by d-flex d-lg-none text-left align-items-center order-3">
                        <img className="mr-3" src={oil} alt="" />
                        <p>{t('maintenance.administeredBy')}</p>
                    </div>
                    <div className="whats-covered col-auto col-lg-6 order-2">
                        <h3 className="pt-2 pb-3">
                            <b>{t('maintenance.whatsCovered.title')}</b>
                        </h3>
                        <ul>
                            {Object.entries(parts)
                                .sort((a, b) => a[0].localeCompare(b[0]))
                                .map(([key, value]) => (
                                    <li className="d-flex align-items-center pb-3" key={`part-${key}`}>
                                        <div>
                                            <img alt="" src={checkMark}></img>
                                        </div>
                                        <p className="d-inline">{value}</p>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    );
};

export default MaintenancePlan;
