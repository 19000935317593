import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mediaQueries } from '../helpers/mediaQueries';

import { useTranslation } from 'react-i18next';

const StyledFooter = styled.footer`
  background-color: transparent;
  padding-top: 20px;
  padding-bottom: 20px;

  ul {
    list-style: none;
    display: flex;
    padding-bottom: 10px;

    ${mediaQueries.greaterThan('md')`
      padding-bottom: 0px;    
    `}

    li {
      &:nth-child(2) {
        margin-left: 30px;
      }
    }
  }
`;

const Footer = () => {
  const { t } = useTranslation('footer');

  const footerLinks = [
    { href: t('privacyRoute'), displayText: t('privacyLink'), tag: 'Privacy link', id: 'privacy' },
    { href: t('legalRoute'), displayText: t('legalLink'), tag: 'Legal link', id: 'legal' },
  ];
  const currentYear = new Date().getFullYear();

  const replaceCurrentYear = copyrightText => copyrightText.replace('{ currentYear }', currentYear);

  return (
    <StyledFooter>
      <div className="container">
        <div className="row justify-content-sm-between align-content-center">
          <div className="col-12 col-md-auto order-2 order-md-1">
            <div className="d-flex justify-content-center justify-content-md-left">
              <p>{replaceCurrentYear(t('copyright'))}</p>
            </div>
          </div>
          <div className="col-12 col-md-auto order-1 order-md-2">
            <ul className="d-flex justify-content-center justify-content-md-left">
              {footerLinks.map(footerLink => (
                <li key={footerLink.id}>
                  <Link to={footerLink.href} allytmln={footerLink.tag}>
                    {footerLink.displayText}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
