import React, { useState } from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
require('intersection-observer');
require('custom-event-polyfill');

import Legal from '../pages/legal';
import Footer from '../shared/Footer';
import Privacy from '../pages/privacy';
import Landing from '../pages/landing';
import SiteNav from '../shared/SiteNav';

import "./App.scss";
import ScrollToTop from '../ScrollToTop';
import NavMenu from '../shared/NavMenu';
import TranslationRoute from '../shared/TranslationRoute';

const App = () => {
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  return (
    <Router>
      <SiteNav showNavMenu={showNavMenu} setShowNavMenu={setShowNavMenu} />
      {showNavMenu && <NavMenu />}
      <ScrollToTop setShowNavMenu={setShowNavMenu} />
      <Switch>
        <TranslationRoute path="/fr/privacy" component={Privacy} />
        <TranslationRoute path="/privacy" component={Privacy} />
        <TranslationRoute path="/fr/legal" component={Legal} />
        <TranslationRoute path="/legal" component={Legal} />
        <TranslationRoute path="/fr" component={Landing} scrolled={scrolled} setScrolled={setScrolled}  lang={"fr"}/>
        <TranslationRoute path="/" component={Landing} scrolled={scrolled} setScrolled={setScrolled} lang={"en"}/>
      </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
