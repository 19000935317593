import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from "lodash";

import AdditionalBenefits from './AdditionalBenefits';
import { createSectionObserver } from '../../../Tagging';
import PlanOptions from './plan-options-table/PlanOptions';
import { mediaQueries } from '../../../helpers/mediaQueries';
import { resources, fallbackLang } from '../../../Language';

const StyledPlanSection = styled.section`
  background-color: #373934;
  padding: 40px 0px;

  ${mediaQueries.greaterThan('md')`
    padding: 65px 0px 40px 0px;
  `}

  h2 {
    outline: none;
    font-size: 24px;

     ${mediaQueries.greaterThan('md')`
        font-size: 30px;
    `}
  }

  h2,
  .expenses {
    color: white;
  }

  b {
    color: inherit;
    font-size: inherit;
  }

  .expenses {
    max-width: 720px;
    margin: 0 auto;
    font-size: 14px;

    ${mediaQueries.greaterThan('md')`
      font-size: 16px;
    `}
  }
`;

const ProtectionPlan = () => {
  const { t } = useTranslation('landing');

  const observer = createSectionObserver('MotorsProtection:Insurance:Protection Plan', 'consumer');

  useEffect(() => {
	observer.observe(document.querySelector("#protection-plan"));
  }, []);

  return (
    <StyledPlanSection id="protection-plan">
      <div className="container">
        <div className="text-left text-md-center">
          <h2 className="mb-4" tabIndex="-1" dangerouslySetInnerHTML={{__html: t('protectionPlan.title')}} />
          <PlanCosts />
          <p className="mt-4 expenses" dangerouslySetInnerHTML={{__html: t('protectionPlan.expenses')}} />
        </div>

        <PlanOptions />
        <AdditionalBenefits />
      </div>
    </StyledPlanSection>
  );
};

/* -------------------------------------------------------------------------- */
/*                                SUB_COMPONENTS                              */
/* -------------------------------------------------------------------------- */
const StyledPlanCosts = styled.div`
  background-color: white;

  p {
    font-size: 14px;

    ${mediaQueries.greaterThan('md')`
      font-size: 16px;
    `}
  }

  ul {
    list-style: none;
  }

  .expenses {
    max-width: 720px;
    margin: 0 auto;
  }
`;

const PlanCosts = () => {
  const { t } = useTranslation('landing');

  const approxCostsKey = 'protectionPlan.approximateCosts';

  const costs = _.get(resources[fallbackLang]['landing'], `${approxCostsKey}.costs`);

  return (
    <StyledPlanCosts className="pt-4 pb-4 px-3">
      <p className="mb-4">{t(`${approxCostsKey}.header`)}</p>
      <div className="mb-0 mb-md-3">
        <ul className="row justify-content-around align-items-end">
          {Object.entries(costs)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([costKey, costValue]) => (
              <li className={`col-12 px-md-0 ${(costKey === "2" || costKey === "4") ? 'col-md-3' : 'col-md-2'}`} key={costKey}>
                <PlanComponent
                  name={t(`${approxCostsKey}.costs.${costKey}.title`)}
                  price={t(`${approxCostsKey}.costs.${costKey}.cost`)}
                />
              </li>
            ))
          }
        </ul>
      </div>
      <p>{t(`${approxCostsKey}.disclaimer1`)} <span className="d-inline d-lg-block">{t(`${approxCostsKey}.disclaimer2`)}</span></p>
    </StyledPlanCosts>
  );
}

const StyledPlanComponent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  ${mediaQueries.greaterThan('md')`
    flex-direction: column;
    justify-content: center;
  `};

  p {
    ${mediaQueries.greaterThan('md')`
      font-size: 20px;
    `}
 
    &.plan__name {
      line-height: 1.25;
    }
  }
`;

const PlanComponent = ({ name, price }) => {
  return (
    <StyledPlanComponent className="mb-2 align-items-center">
      <p className="plan__name text-sm-center font-weight-bold" dangerouslySetInnerHTML={{ __html: name }}></p>
      <p className="ml-auto ml-md-0 mt-0 mt-md-1">{price}</p>
    </StyledPlanComponent>
  );
};

PlanComponent.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
};

export default ProtectionPlan;
