import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

const TranslationRoute = ({ path, component : Component, ...rest }) => {
    setTimeout(() => {
        if (path.match(/^\/fr/)) {
            if (i18next.language !== 'fr') {
                i18next.changeLanguage('fr');
                document.documentElement.lang = 'fr';
            }
        } else {
            if (i18next.language !== 'en') {
                i18next.changeLanguage('en');
                document.documentElement.lang = 'en';
            }
        }
    });

    return (
        <Component {...rest} />
    );
}

export default TranslationRoute;

TranslationRoute.propTypes = {
    path: PropTypes.string,
    component: PropTypes.any
};