import en from './assets/en.json';
import fr from './assets/fr.json';

const fallbackLang = window.location.pathname.match(/^\/fr/) ? 'fr' : 'en';

let resources;
if (fallbackLang === 'en') {
    resources = { en, fr };
} else {
    resources = { fr, en };
}

document.documentElement.lang = fallbackLang;

export {resources, fallbackLang};