import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import _ from "lodash";

import { mediaQueries } from '../../../helpers/mediaQueries';
import { resources, fallbackLang } from '../../../Language';
import caretRight from '../../../assets/caret-right.svg';
import parseStatements from 'postcss-import/lib/parse-statements';

const questionsSubKey = 'faqs.questions';

const StyledButton = styled.button`
    padding: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #0071c4;
    display: flex;
    align-items: top;
    background-color: transparent;
    border: 0;

    :hover {
        text-decoration: underline;
    }
`;

const CustomToggle = ({ pageKey, faqKey, openPanelKey, setOpenPanelKey, firstFocusableContentRef, children }) => {
    const decoratedClick = useAccordionButton(faqKey, () => {
        const newState = !isPanelOpen();
        setPanelState(newState);
        if (newState) {
            setTimeout(() => firstFocusableContentRef.current.focus());
        }
    });

    const setPanelState = (open) => setOpenPanelKey(open ? faqKey : null);

    const isPanelOpen = () => openPanelKey === faqKey;

    return (
        <StyledButton
            aria-selected={isPanelOpen() ? "true" : "false"}
            aria-controls={`panel-${faqKey}`}
            role="tab"
            allytmln={_.get(resources['en'][pageKey], `${questionsSubKey}.${faqKey}.question`)}
            onClick={decoratedClick}
        >
            {children}
        </StyledButton>
    );
}

CustomToggle.propTypes = {
    pageKey: PropTypes.string,
    faqKey: PropTypes.string,
    openPanelKey: PropTypes.string,
    setOpenPanelKey: PropTypes.func,
    firstFocusableContentRef: PropTypes.any,
    children: PropTypes.array
};

const CardWrapper = styled.div`
    .card {
        border: 0;
        border-radius: 0;
    }

    .card-header {
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 10px 0;
    }

    .card-body {
        padding: 0 0 0 20px;
        margin: 10px 0 15px 0;
    }

    p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    img {
        transition: transform .4s;
        width: 8px;
        margin-top: -2px;

        &.down {
            transform: rotate(90deg);
        }
    }

    .question {
        color: inherit;
        padding-left: 10px;
        text-align: left;
    }
`;

const Faq = ({ pageKey, faqKey, openPanelKey, setOpenPanelKey }) => {
    const { t } = useTranslation(pageKey);

    const faqRootKey = `${questionsSubKey}.${faqKey}`;

    const answer = _.get(resources[fallbackLang][pageKey], `${faqRootKey}.answer`);

    const firstFocusableContentRef = useRef(null);

    const isPanelOpen = () => openPanelKey === faqKey;

    const stripHtmlTags = (originalString) => originalString.replace(/(<([^>]+)>)/gi, "");

    return (
        <CardWrapper>
            <Card>
                <Card.Header>
                    <CustomToggle
                        pageKey={pageKey}
                        faqKey={faqKey}
                        openPanelKey={openPanelKey}
                        setOpenPanelKey={setOpenPanelKey}
                        firstFocusableContentRef={firstFocusableContentRef}
                    >
                        <div>
                            <img alt="" className={isPanelOpen() ? "down" : ""} src={caretRight} />
                        </div>
                        <span className="question">{t(`${faqRootKey}.question`)}</span>
                    </CustomToggle>
                </Card.Header>
                <Accordion.Collapse
                    eventKey={faqKey}
                    role="tabpanel"
                    aria-expanded={isPanelOpen() ? "true" : "false"}
                    aria-hidden={isPanelOpen() ? "false" : "true"}
                    id={`panel-${faqKey}`}
                >
                    <Card.Body>
                        {Object.entries(answer).sort((a, b) => a[0].localeCompare(b[0])).map(([answerKey, answerValue], answerIndex) => (
                            <p
                                tabIndex="-1"
                                style={{outline: 'none'}}
                                ref={answerIndex === 0 ? firstFocusableContentRef : null}
                                key={answerKey}
                                dangerouslySetInnerHTML={{__html: t(`${faqRootKey}.answer.${answerKey}`)}}
                                aria-label={stripHtmlTags(t(`${faqRootKey}.answer.${answerKey}`))}
                            />
                        ))}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </CardWrapper>
    );
}

Faq.propTypes = {
    pageKey: PropTypes.string,
    faqKey: PropTypes.string,
    t: PropTypes.func,
    openPanelKey: PropTypes.string,
    setOpenPanelKey: PropTypes.func
};

const StyledSection = styled.section`
    padding: 40px 0px;

    ${mediaQueries.greaterThan('md')`
        padding: 65px 0px 40px 0px;
    `}

    h2 {
        font-size: 24px;
        line-height: 36px;
        font-weight: bold;
        margin-bottom: 20px;

        ${mediaQueries.greaterThan('md')`
            font-size: 30px;
            line-height: 45px;
            margin-bottom: 15px;
        `}
    }

    p {
        font-size: 14px;
        line-height: 21px;

        ${mediaQueries.greaterThan('md')`
            font-size: 16px;
            line-height: 24px;
        `}
    }

    .footer {
        margin-top: 20px;
    }
`;

const Faqs = ({ pageKey }) => {
    const { t } = useTranslation(pageKey);
    const questions = _.get(resources[fallbackLang][pageKey], questionsSubKey);
    const [openPanelKey, setOpenPanelKey] = useState(null);

    return (
        <StyledSection>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-9">
                        <h2>{t('faqs.title')}</h2>

                        <Accordion role="tablist">
                            {Object.entries(questions).sort((a, b) => a[0].localeCompare(b[0])).map(([questionKey, questionValue]) =>
                                <Faq
                                    key={questionKey}
                                    pageKey={pageKey}
                                    faqKey={questionKey}
                                    openPanelKey={openPanelKey}
                                    setOpenPanelKey={setOpenPanelKey}
                                />
                            )}
                        </Accordion>

                        <p className="footer">{t('faqs.footer')}</p>
                    </div>
                </div>
            </div>
        </StyledSection>
    );
}

Faqs.propTypes = {
    pageKey: PropTypes.string
};

export default Faqs;