import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mediaQueries } from '../../../helpers/mediaQueries';


const WhoSectionStyling = styled.section`
    background-color: #5e295f;

    h2 {
        margin-top: 15px;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.5;
        color: #ffffff;

        ${mediaQueries.greaterThan('md')`
            font-size: 30px;
        `}
    }

    sup {
        color: #ffffff;
        font-size: 10px;
    }

    p#text {
        margin-top: 10px;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;

        ${mediaQueries.greaterThan('md')`
            font-size: 16px;
        `}
    }

    #WhoSection {
        padding: 40px 0px;
    }
`

const WhoSection = () => {
    const { t } = useTranslation('landing');

    return (
        <WhoSectionStyling>
            <div className="container">
                <div className="row" id="WhoSection">
                    <div className="col-12 col-lg-9">
                        <h2 dangerouslySetInnerHTML={{ __html: t('whoSection.title')}} />

                        <p id="text" dangerouslySetInnerHTML={{ __html: t('whoSection.description')}} />
                    
                    </div>
                </div>
            </div>
        </WhoSectionStyling>
    );
};

export default WhoSection;