import React from 'react';
import PropTypes from 'prop-types';
import Overlay from 'react-bootstrap/Overlay';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Dejargonator from './Dejargonator';
import questionMark from '../../../../assets/dejarg-question-mark.cur';
import { resources } from '../../../../Language';

const Trigger = styled.button`
    border: 0;
    background: none;
    padding: 0;
    margin: 0;
    text-align: inherit;
    line-height: 1;

    :hover {
        cursor: url(${questionMark}), help;
    }

    .underline--dotted {
        border-bottom: dotted 1px #2a2a2a;
    }
`;

const HiddenElement = styled.span`
    padding: 0;
    margin: 0;
    height: 0;
    width: 0;
`;

const Icon = styled.img`
    width: 16px;
    margin-left: 5px;
`;

const DejargonatorTrigger = ({ pageKey, dejargonatorsRootKey, dejargonatorKey, openPopover, setOpenPopover, icon }) => {
    const { t } = useTranslation(pageKey);
    const dejargonatorTriggerRootKey = `${dejargonatorsRootKey}.${dejargonatorKey}`;
    const dejargonatorRootKey = `${dejargonatorTriggerRootKey}.popover.sections`;
    const firstFocusableContentRef = React.useRef(null);

    const showPopover = (show) => {
        if (show) {
            setOpenPopover(dejargonatorKey);
        } else {
            if (openPopover) {
                setOpenPopover(null);
                setTimeout(() => triggerRef.current.focus(), 300);
            }
        }
    };

    const isPopoverShowing = () => openPopover === dejargonatorKey;
    const triggerRef = React.useRef(null);
    const parentRef = React.useRef(null);

    const hiddenElement = (
        <HiddenElement aria-label="" onFocus={() => showPopover(false)} tabIndex={isPopoverShowing() ? '0' : '-1'} />
    );

    return (
        <>
            <span ref={parentRef}>
                <Trigger
                    aria-label={`${t(`generalNav.select`)} ${t(`${dejargonatorTriggerRootKey}.accessibleText`)} 
                    ${t(`generalNav.toLearnMore`)}`}
                    ref={triggerRef}
                    onClick={() => showPopover(!isPopoverShowing())}
                    onFocus={() => showPopover(false)}
                    onMouseEnter={() => showPopover(true)}
                    onMouseLeave={() => showPopover(false)}
                    allytmln={`${_.get(
                        resources['en'][pageKey],
                        `${dejargonatorTriggerRootKey}.accessibleText`
                    )} tooltip`}>
                    <span
                        className="underline--dotted"
                        dangerouslySetInnerHTML={{ __html: t(`${dejargonatorTriggerRootKey}.displayText`) }}
                    />
                    <Icon alt="" src={icon} />
                </Trigger>
                <Overlay
                    target={triggerRef.current}
                    show={isPopoverShowing()}
                    placement="bottom"
                    rootClose={true}
                    onHide={() => showPopover(false)}
                    onEntered={() => firstFocusableContentRef.current.focus()}
                    container={parentRef}>
                    {Dejargonator({ pageKey, dejargonatorRootKey, firstFocusableContentRef })}
                </Overlay>
            </span>
            {isPopoverShowing() && hiddenElement}
        </>
    );
};

DejargonatorTrigger.propTypes = {
    pageKey: PropTypes.string,
    dejargonatorsRootKey: PropTypes.string,
    dejargonatorKey: PropTypes.string,
    openPopover: PropTypes.string,
    setOpenPopover: PropTypes.func,
    icon: PropTypes.element
};

export default DejargonatorTrigger;
