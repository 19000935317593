import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TranslationLink from './TranslationLink';
import { mediaQueries } from '../helpers/mediaQueries';
import { sendSkipToMainFocusEvent } from '../Events';

import closeButton from '../assets/close.svg';
import hamburgerButton from '../assets/hamburger-menu.svg';
import mobileLogo from '../assets/sticky-motors-protection.png';

const Header = styled.header`
    background-color: #fff;
    border-bottom: #979797 solid 1px;
    a:focus {
        outline: none;
    }

    nav {
        height: 64px;
        position: relative;

        .logo {
            height: 40px;

            ${mediaQueries.greaterThan('lg')`
                height: 44px;
            `}
        }

        .mobile-logo {
            height: 34px;
        }
    }

    ul {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;

        li a {
            font-weight: bold;
            color: #0071c4;
        }

        li:not(:last-child) {
            margin-right: 30px;
        }
    }

    .menu-button {
        border: none;
        margin-bottom: 0;
        background: none;
        font-weight: bold;
		width: 20px;
		height: 12px;
        background-size: 100% 100%;

        &__hamburger {
            background-image: url(${hamburgerButton});
        }

        &__close {
            background-image: url(${closeButton});
        }
    }

    .skip-to-main-button:focus {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        background-color: #006899;
        border: 1px solid #000000;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 0.5rem 1rem;
        width: 180px;
    }
`;

const SiteNav = ({ showNavMenu, setShowNavMenu }) => {
  const { t } = useTranslation('header');

  const handleMenuOpen = () => {
    document.body.classList.add('lock-scroll');
    setShowNavMenu(true);
  };

  const handleMenuClose = () => {
    document.body.classList.remove('lock-scroll');
    setShowNavMenu(false);
  };

  const logoUrl = () => {
    const translated = t('logo');
    return require(`../assets/${translated}`);
  };

  const logoContentRef = useRef(null);

  useEffect(() => {
    logoContentRef.current.focus();

    document.addEventListener('pageLoadFocus', () => {
        setTimeout(() => {
            if (document.activeElement) {
                document.activeElement.blur();
            }
            setTimeout(() => {
                if (logoContentRef.current) {
                    logoContentRef.current.focus();
                }
            });
        });
    });
  }, []);

  let menuButtonClickHandler;
  let menuButtonAriaLabel;
  let menuButtonStyle;

  if (showNavMenu) {
    menuButtonClickHandler = handleMenuClose;
    menuButtonStyle = 'menu-button__close';
    menuButtonAriaLabel = 'Close Mobile Menu';
  } else {
    menuButtonClickHandler = handleMenuOpen;
    menuButtonStyle = 'menu-button__hamburger';
    menuButtonAriaLabel = 'Open Mobile Menu';
  }

  return (
    <Header className="w-100">
      <div className="container">
        <nav className="row no-gutters align-items-center justify-content-between" aria-label="Site navigation">
          <div className="col-auto d-flex d-lg-none align-items-center">
            <button
              onClick={menuButtonClickHandler}
              aria-label={`${menuButtonAriaLabel}`}
              className={`${menuButtonStyle} menu-button`}
            />
          </div>

          <div className="col-auto">
            <Link ref={logoContentRef} allytmln="Home link (main nav)" to={t('homeRoute')}>
              <img alt="Motors Protection Plan logo" className="logo" src={logoUrl()} />
            </Link>
          </div>

          <button allytmln={t('skipToMainContent')} className="visually-hidden-focusable skip-to-main-button" onClick={() => sendSkipToMainFocusEvent()}>
          {t('skipToMainContent')}
          </button>

          <div className="col-auto">
            <ul className="d-none d-lg-block">
              <li>
                <TranslationLink link={{ tag: `Change language to ${t('languageLink')} link`, displayText: t('languageLink') }} />
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </Header>
  );
};

SiteNav.propTypes = {
  showNavMenu: PropTypes.bool,
  setShowNavMenu: PropTypes.func,
  scrolled: PropTypes.bool
};

export default SiteNav;
