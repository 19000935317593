const sendPageLoadFocusEvent = () => {
    const event = new CustomEvent('pageLoadFocus');
    document.dispatchEvent(event);
};

const sendSkipToMainFocusEvent = () => {
    const event = new CustomEvent('skipToMainFocus');
    document.dispatchEvent(event);
};

export { sendPageLoadFocusEvent, sendSkipToMainFocusEvent };
